import React from "react"
import { Link } from "gatsby"

export default (props) => (
  <div>
    <div className="content more-work">
      <div className="links-to-work">
        <Link to={props.prevURL} className="project prev">
          <span>Prev &#8212;</span> {props.prevWorkTitle}
        </Link>
        <Link to={props.nextURL} className="project next">
          <span>Next &#8212;</span> {props.nextWorkTitle}
        </Link>
      </div>
    </div>
    <div className="content purpleBg pushTopBottom">
      <div className="wrapper full">
        <h1 className="white">{props.purpleText}</h1>
        <a className="button white" href="mailto:jeff@jayjodesign.com?subject=Let's chat about a project...">{props.buttonText}</a>
      </div>
    </div>
  </div>
)