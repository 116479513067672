import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Helmet from "react-helmet"

import Quote from "../../../components/quote"
import Morework from "../../../components/more-work"
import Layout from "../../../components/layout"

export default (props) => (  
  <Layout>
  <Helmet 
    bodyAttributes={{class:'work-example grey-four'}}
    title="JayJo Design | Jeffrey Jorgensen | Work | Mixpanel - Design System"
  />   
    <div>
      <section className="study">
        <div className="content">
          <div className="wrapper first">
            <p className="title-header grey-two">Mixpanel</p>
            <h1 className="study-header">Design System</h1>
          </div>
          <Img 
            fluid={props.data.hero.childImageSharp.fluid}
            placeholderClassName="screengrab full"
            title="The final UI design for the first version of the Mixpanel Design System."
            alt="The final UI design for the first version of the Mixpanel Design System."
            loading="eager"
          />
        </div>
        <div className="content split">
          <div className="wrapper first">
            <div className="two-col two-thirds">
              <div className="col one">
                <h1 className="purple">We needed to move faster...</h1>
                <p className="intro">The entire organization felt the strain of wanting and needing to move faster, and with more efficiency.</p>
                <p>In addition, having grown to a design team of 11, and an EPD team of nearly 60, it was imperative we unified not only our product interface, but our product process. With multiple designers and PMs and no dedicated person to maintain the product's design direction, multiple variations of primitives, controls, and components found their way into different reports.</p>
                <p>We had to find a strategic and realistic way to develop this system, and implement it across an already 8 year-old product, and a team used to their habits.</p>
              </div>
              <div className="col two">
                <div className="purpleBg details">
                  <p className="title-header">Project Deets</p>
                  <ul>
                    <li>Year &#8212; <span>2017</span></li>
                    <li>Role &#8212; <span>Design Lead (UX)</span></li>
                    <li>Skills &#8212; <span>UX</span></li>
                  </ul>
                  <p className="title-header">Product Goals</p>
                  <ul>
                    <li className="diamond">Identify high-priority areas for MVP</li>
                    <li className="diamond">Integrate the system across various products</li>
                    <li className="diamond">Disseminate info to the organization</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <div className="wrapper narrow-med">
            <h1 className="purple">Where to start...</h1>
            <p>Over the years I’d started a product audit so we already had a jump on some of the UI problem areas in terms of inconsistencies.</p>
            <p>I'd broken the product UI out into its various components based loosely on <a href="https://bradfrost.com/blog/post/atomic-web-design/" target="_blank" rel="noopener noreferrer">Brad Frost's Atomic Design</a> principles, and the function each component served within Mixpanel.</p>
            <div className="break"></div>
          </div>
            <a className="image-link" href="/static/233060a4bb43d461dad649aa4c1840c6/3ba25/product-audit-full%402x.png" rel="noopener noreferrer" target="_blank">
              <Img 
                fluid={props.data.productAudit.childImageSharp.fluid}
                className="screengrab purple-shadow"
                alt="A product audit I began working on within weeks of starting at Mixpanel in 2014. Top: Some buttons. Bottom: Some drop downs." 
                title="A product audit I began working on within weeks of starting at Mixpanel in 2014. Top: Some buttons. Bottom: Some drop downs."
              />
            </a>
          <div className="caption">A product audit I began working on within weeks of starting at Mixpanel in 2014. Top: Some buttons. Bottom: Some drop downs.
          </div>
          <div className="wrapper narrow-med">
            <p>After much deliberation as an EPD team, we came to the conclusion it would be best to start with:</p>
            <ul>
              <li className="diamond-purple">Color</li>
              <li className="diamond-purple">Typography</li>
              <li className="diamond-purple">CTAs (More specifically, buttons)</li>
            </ul>
            <p>Color and typography would have the biggest impact in laying a foundation for unifying the product and creating a solid visual language. Consistent CTAs was a step toward giving our users the muscle memory and confidence to tackle the tasks they came to Mixpanel to tackle.</p>
          </div>
        </div>
        <div className="content">
          <div className="wrapper narrow-med">
            <h1 className="purple">Move fast. Hack things.</h1>
            <p>I wrote a short Javascript that crawled the Mixpanel UI stylesheets and js files pulling out all colors and converted them to 2,015 unique hexcodes. I then did the same for fonts and found 35 unique font-size and 42 unique font-family declarations.</p>
            <div className="break"></div>
          </div>
          <a className="image-link" href="/static/be1e5dfe3ca2aab745aedff10cd65a22/3ba25/js-color-grabber%402x.png" target="_blank">
            <Img 
              fluid={props.data.colorGrabber.childImageSharp.fluid}
              className="screengrab purple-shadow" 
              alt="A group of colors found by the CSS and JS file crawler application I wrote." 
              title="A group of colors found by the CSS and JS file crawler application I wrote."
            />
          </a>
          <div className="caption">A group of colors found by the CSS and JS file crawler application I wrote.</div>
          <div className="wrapper narrow-med">
            <p>After we had all the colors from our marketing site and product properties, I wrote a script to sort them based on their HSL values. From there it would be much easier to pin-point like-colors to Find & Replace throughout the UI.</p>
          </div>
          <div className="break"></div>
          <a className="image-link" href="/static/f47574ec520ce2b02261df8a1e1c551d/3ba25/js-color-sorted%402x.png" target="_blank">
            <Img 
              fluid={props.data.colorSorted.childImageSharp.fluid}
              className="screengrab purple-shadow"
              alt="The same group of colors sorted by their hue, saturation, and lightness values." 
              title="The same group of colors sorted by their hue, saturation, and lightness values."
            />
          </a>
          <div className="caption">The same group of colors sorted by their hue, saturation, and lightness values.</div>
          <div className="wrapper narrow-med">
            <p>After multiple iterations, practical applications, and usability tests (especially for color and accessiblity) we established a foundation for Mixpanel's Design System. </p>
            <ul>
              <li className="diamond-purple"><b>Colors</b> &#8212; 15 for UI, 8 for data visualization (23 total)</li>
              <li className="diamond-purple"><b>Typography</b> &#8212; 1 font, 3 weights, 6 sizes</li>
              <li className="diamond-purple"><b>Buttons</b> &#8212; Primary & secondary (with variations depending on application)</li>
            </ul>
            <div className="break"></div>
          </div>
          <a className="image-link" href="/static/e8dbb7ce9b07794db48e2376e51b03b4/3ba25/ds-version-one.png" target="_blank">
            <Img 
              fluid={props.data.versionOne.childImageSharp.fluid}
              className="screengrab purple-shadow"
              alt="The first version of our Design System consisted of Buttons, Color, and Typography." 
              title="The first version of our Design System consisted of Buttons, Color, and Typography."
            />
          </a>
          <div className="caption">The first version of our Design System consisted of Buttons, Color, and Typography.</div>
        </div>
        <div className="content">
          <div className="wrapper narrow-med">
            <h1 className="purple">Maintaining and integrating.</h1>
            <p>Integration was one of the more difficult problems to solve. In an ideal world, we’d have a single source of truth that would pull in updates we made to design elements, and the push them to their respective properties in code. Three years later, there are entire companies dedicated to this exact problem (Abstract, Sketch Cloud, Figma, etc.) </p>
            <p>Since this was a V1 (and to a certain extent a proof of concept), we didn't have the time or resources to do it "right". </p>
            <p>We finally landed on a solution that while not automated, worked for our team and its size.</p>
          </div>
          <div className="wrapper">
            <h1 className="purple step">Step 1</h1>
            <p className="centered">Present your design proposal to the team outlining why the component or change is necessary:</p>
            <a className="image-link" href="/static/4c3069dedafbd4ed6f7ea4bc44b993e0/3ba25/component-proposal%402x.png" target="_blank">
              <Img
                fluid={props.data.proposal.childImageSharp.fluid}
                className="screengrab purple-shadow" 
                alt="An example of a proposal posted to Slack for implementing a new component." 
                title="An example of a proposal posted to Slack for implementing a new component."
              />
            </a>
            <div className="caption">An example of a proposal posted to Slack for implementing a new component.</div>
            <h1 className="purple step">Step 2</h1>
            <p className="centered">If approved, add your component to the Design System Master sticker sheet:</p>
            <a className="image-link" href="/static/afd7e8d732f2a2f5089c5a135142a5e6/3ba25/proposal-step-2%402x.png" target="_blank">
              <Img
                fluid={props.data.proposalTwo.childImageSharp.fluid}
                className="screengrab purple-shadow" 
                alt="The Mixpanel Design Master sticker sheet." 
                title="The Mixpanel Design Master sticker sheet."
              />
            </a>
            <div className="caption">The Mixpanel Design Master sticker sheet.</div>
            <h1 className="purple step">Step 3</h1>
            <p className="centered">The designer then notifies either myself or the other Design Systems designer:</p>
              <Img
                fluid={props.data.chatWindow.childImageSharp.fluid}
                className="screengrab" 
                alt="A text bubble with a conversation between designers." 
                title="A text bubble with a conversation between designers."
              />
            <div className="caption">A text bubble with a conversation between designers.</div>
            <h1 className="purple step">Step 4</h1>
            <p className="centered">Component is built and added to a Github repo and a PR is opened for engineering review:</p>
            <a className="image-link" href="/static/9a36530697216d59bc2a0cc8c9cf7fba/3ba25/proposal-step-4%402x.png" target="_blank">
              <Img
                fluid={props.data.proposalFour.childImageSharp.fluid}
                className="screengrab purple-shadow"
                alt="A screenshot of the Mixpanel Design System website which pulls from a Github repo." 
                title="A screenshot of the Mixpanel Design System website which pulls from a Github repo."
              />
            </a>
            <div className="caption">A screenshot of the Mixpanel Design System website which pulls from a Github repo.</div>
          </div>
          <div className="wrapper narrow-med">
            <h1 className="purple">There was of course one last step...</h1>
            <p className="centered">It was necessary we make sure everyone knew what updates had been made to MP Common.</p>
            <a className="image-link" href="/static/50b7e08aa83fcb8c18d21d57adc85978/3ba25/slack-conversation%402x.png" target="_blank">
              <Img
                fluid={props.data.slackWindow.childImageSharp.fluid}
                className="screengrab purple-shadow"
                alt="A screenshot of Slack, showing a SlackBot notifying the channel of the changelog." 
                title="A screenshot of Slack, showing a SlackBot notifying the channel of the changelog."
              />
            </a>
            <div className="caption">A screenshot of Slack, showing a SlackBot notifying the channel of the changelog.</div>
          </div>
        </div>
        <div className="content">
          <div className="wrapper narrow-med">
            <h1 className="purple">Closure and follow-up.</h1>
            <p>The Mixpanel Design System was a highly-successful internal project. While tough to measure, we estimated it helped increase effeciency within the team by ~30%. Perhaps the most important thing it did however, was boost the morale of the team as a whole. It brought the design team and EPD organization together more than anything else we’d built because everyone was working in lockstep the entire way.</p>
            <p>It’s still being used today, including most of the patterns, elements, and components we established in the beginning.</p>
          </div>
          <div className="wrapper">
            <Quote
              quoteText="Aside from his excellent UX chops, I was very grateful for how hard Jeff pushed for improved relationships and process between all of the EPD org. He's clearly very passionate not only about getting things right for the customer, but also about making sure we are using our own resources most effectively across the teams. We've got a long way to go, but we wouldn't have come even as far as we have without Jeff's drive."
              subject="- Engineering Colleague, 2017"
            />
          </div>
        </div>
        <div className="content">
          <div className="wrapper narrow-med">
            <div className="done-differently">
              <h1>Things I would do differently</h1>
              <p className="intro">I would speak with more executives and broaden the userbase/usertype we interviewed.</p>
              <p>After launch, we learned people lower in the organization would often forward KPIs onto higher-level colleagues who weren’t regular Mixpanel users. Mixpanel mobile didn’t afford them the ability to do this, and thus we had to re-enable the Digests feature for ~20 companies.</p>
              <p>Two years later we eventually redesigned and rebuilt Email Digests making them move robust and powerful.</p>
            </div>
          </div>
        </div>
        <Morework 
          prevURL="/work/mixpanel/email-ios"
          prevWorkTitle="Mixpanel Email Digests"
          nextURL="/work/mixpanel/insights"
          nextWorkTitle="Mixpanel Insights"
          purpleText="Want to see what we can do?"
          buttonText="Let's Work Together"
        />
      </section>
    </div>
  </Layout>
)

// Create a fragment for all images on the page
export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 2000, quality: 100) {
        ...GatsbyImageSharpFluid
        src
      }
    }
  }
`

// Get the Site Meta Data & JayJo Links
export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    hero: file(relativePath: {eq: "work/mixpanel/design-system/mp-design-system@2x.png"}) {
      ...fluidImage
    }
    productAudit: file(relativePath: {eq: "work/mixpanel/design-system/product-audit@2x.png"}) {
      ...fluidImage
    }
    colorGrabber: file(relativePath: {eq: "work/mixpanel/design-system/js-color-grabber@2x.png"}) {
      ...fluidImage
    }
    colorSorted: file(relativePath: {eq: "work/mixpanel/design-system/js-color-sorted@2x.png"}) {
      ...fluidImage
    }
    versionOne: file(relativePath: {eq: "work/mixpanel/design-system/ds-version-one.png"}) {
      ...fluidImage
    }
    proposal: file(relativePath: {eq: "work/mixpanel/design-system/component-proposal@2x.png"}) {
      ...fluidImage
    }
    proposalTwo: file(relativePath: {eq: "work/mixpanel/design-system/proposal-step-2@2x.png"}) {
      ...fluidImage
    }
    chatWindow: file(relativePath: {eq: "work/mixpanel/design-system/chat-window@2x.png"}) {
      ...fluidImage
    }
    proposalFour: file(relativePath: {eq: "work/mixpanel/design-system/proposal-step-4@2x.png"}) {
      ...fluidImage
    }
    slackWindow: file(relativePath: {eq: "work/mixpanel/design-system/slack-conversation@2x.png"}) {
      ...fluidImage
    }
  }
`